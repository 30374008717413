'use client'
import Cookies from 'js-cookie'
import {useEffect} from 'react'
import {Analytics} from '~/utils/Analytics'
import {type selectPageVariant} from '../../selectPageVariant'

type EnrollExperimentProps = {
  pageVariant: Awaited<ReturnType<typeof selectPageVariant>>
}
export function EnrollExperiment(props: EnrollExperimentProps) {
  const {pageVariant} = props
  useEffect(() => {
    if (pageVariant._type === 'experiment-disabled') return
    if (pageVariant._type === 'experiment-resumed') return

    const {cookieName, cookieValue, experimentName, variantName, isControl, weight} =
      pageVariant
    Cookies.set(cookieName, cookieValue, {expires: 365})

    Analytics.track('experiment_enrolled', {
      experiment_name: experimentName,
      experiment_branch: variantName,
      branch_type: isControl ? 'control' : 'test',
      branch_enrollment_weight: weight,
    })
  }, [pageVariant])
  return null
}
